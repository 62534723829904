import './services.css';
import { Helmet } from 'react-helmet-async';

const Services = () => {
    const dropdown = () => {
        const menu = document.querySelector('.dropdown-menu');
        menu.classList.contains('hide')
        ? menu.classList.remove('hide')
        : menu.classList.add('hide')
    }

    return (
        <div id='services-container'>
            <Helmet>
                <title>Dronetri Solar Surveying Drone Services</title>
                <meta
                    name='description'
                    content='
                        Dronetri offers solar surveying drone services in the Las Vegas and
                        surrounding areas'
                />
            </Helmet>
            <h2>Services</h2>
            {/* <div><h3>Select Service ↓</h3></div> */}
            <section className='services-section'>
                <h3 className="service-dropdown">
                    - Solar Surveying -
                </h3>
                <div className='img-with-text'>
                    <div className='services-img-container img1'>
                        {/* <img className='service-img' src="/topdown.jpg" alt="topdown" /> */}
                    </div>
                    <div className='img-text'>
                    <p>
                        Utilizing drones with high-resolution photo capabilities and automated 
                        flight software, we ensure precise data capture for creating detailed maps 
                        essential for solar panel placement, shading analysis, and optimizing energy 
                        efficiency.
                    </p>
                    </div>
                </div>
                <div className='img-with-text'>
                    <div className='services-img-container img2'>
                        {/* <img className='service-img' src='/roof-structure.jpg' alt='roof-truss' /> */}
                    </div>
                    <div className='img-text'>
                        <p>
                            In addition to our aerial surveys, our comprehensive ground survey 
                            gathers vital supplementary data. This includes detailed information on 
                            electrical panels and load-bearing sources such as AC units and pool pumps. <br/><br/>
                            Moreover, we conduct thorough roof structure surveys, obtaining accurate 
                            measurements, 360-degree photos, and documentation of any damages. 
                        </p>
                    </div>
                </div>
                <div className='img-with-text'>
                    <div className='services-img-container img3'>
                        {/* <img className='service-img' src='/floorplan.png' alt='floorplan' /> */}
                    </div>
                    <div className='img-text'>
                        <p>
                            When necessary, we provide comprehensive floor plans to further enhance your 
                            solar project's understanding and planning.<br/><br/>
                            Our comprehensive surveys ensure precise and reliable results, 
                            setting the foundation for a successful solar project.
                        </p>
                    </div>
                </div>
            </section>
            <section className='services-section'>
                <h3 className="service-dropdown">
                    - Real Estate -
                </h3>
                <div className='img-with-text'>
                    <div className='services-img-container img4'>
                        {/* <img className='service-img' src='/frontface.jpg' alt='frontface' /> */}
                    </div>
                    <div className='img-text'>
                        <p>
                            Capture stunning aerial images and videos to showcase your 
                            properties and more from captivating and unique angles.
                        </p>
                    </div>
                </div>
            </section>
            {/* <section>
                <h3 className="service-dropdown">
                    Mapping →
                </h3>
                <img className='service-img' src='/baseball_fields-small.jpg' alt='baseball fields' />
                <p>
                    Our drones are equipped to conduct thorough inspections of infrastructure, 
                    buildings, power lines, and more, providing valuable data without the need for risky
                    manual inspections.
                </p>
            </section> */}
        </div>
    )
}

export default Services;