/* eslint-disable react-hooks/exhaustive-deps */
import './form.css';
import * as yup from 'yup';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const formSchema = yup.object().shape({
    name: yup.string()
            .min(3, 'Name too short')
            .max(30, 'Name too long')
            .required(),
    email: yup.string()
            .email('Invalid email')
            .required('Email required'),
    project: yup.string().required('Select a project type'),
    location: yup.string().required('Select a project location'),
    comments: yup.string()
})

const ContactUs = ({ form, onChange, onSubmit, formErrors, setFormErrors, messages, isDisabled, setIsDisabled }) => {

    useEffect(() => {
        formSchema
          .validate(form)
          .then(() => {
            setIsDisabled(false);
          })
          .catch(() => {
            setIsDisabled(true);
          });
    }, [form, formSchema]);

    const handleBlur = e => {
        const { name, value } = e.target;
        formValidation(name, value);
    }

    const formValidation = async (name, value) => {
        await yup.reach(formSchema, name).validate(value)
        .then(() => setFormErrors({...formErrors, [name]: ''}))
        .catch(err => setFormErrors({...formErrors, [name]: err.message}))
    }

    return (
        <div className='form-container'>
            <Helmet>
                <title>Contact Us</title>
                <meta
                    name='description'
                    content='contact Dronetri about solar surveying and photography drone services'
                />
            </Helmet>
            <h2>Contact Us</h2>
            <div className='error-container'>
                {messages.map(message => <span className='error-message'>{message}</span>)}
            </div>
            <form onSubmit={onSubmit}>
                <div className='form-section'>
                    <label htmlFor="name">Name</label>
                    <input
                        className='form-input'
                        name="name" onBlur={handleBlur}
                        type="text" placeholder='name'
                        value={form.name}
                        onChange={onChange}
                    />
                </div>
                <div className='form-section'>
                    <label htmlFor="email">Email</label>
                    <input
                        className='form-input'
                        name="email" onBlur={handleBlur}
                        type="email" placeholder='email'
                        value={form.email}
                        onChange={onChange}
                    />
                </div>
                <div className='form-section'>
                    <label htmlFor="service">Project Type</label>
                    <select
                        className='form-select'
                        name="project"
                        value={form.project}
                        onChange={onChange}
                    >
                        <option value='solar survey'>Solar Survey</option>
                        <option value='real estate'>Real Estate Photography</option>
                    </select>
                </div>
                <div className='form-section'>
                    <label htmlFor="location">Project Location</label>
                    <select
                        className='form-select'
                        name="location"
                        value={form.location}
                        onChange={onChange}
                        onBlur={handleBlur}
                    >
                        <option value=''>-Select One-</option>
                        <option value='las vegas'>Las Vegas</option>
                        <option value='pahrump'>Pahrump</option>
                        <option value='laughlin'>Laughlin</option>
                        <option value='bullhead city'>Bullhead City</option>
                        <option value='st george'>St. George</option>
                        <option value='phoenix'>Phoenix</option>
                    </select>
                </div>
                <div className='form-section'>
                    <label htmlFor="comments">Comments</label>
                    <textarea
                        className='form-textarea'
                        name="comments"
                        onBlur={handleBlur}
                        type="textarea"
                        rows="10"
                        placeholder='Tell us a little more about your project'
                        value={form.comments}
                        onChange={onChange}
                    />
                </div>
                <div className='form-section'>
                    <input
                        className={`form-input submit-btn ${isDisabled === true ? 'disabled' : 'hello'}`}
                        disabled={isDisabled}
                        type='submit'
                        value="Submit"
                    />
                </div>
            </form>
        </div>
    )
}

export default ContactUs;