import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
    return (
        <div>
            <Helmet>
                <title>About Dronetri</title>
                <meta
                    name='description'
                    content='
                        Dronetri drone solar surveyng services Las Vegas
                        and the surrounding areas.
                    '
                />
            </Helmet>
            <h2>About Us</h2>
            <p>
                At Dronetri, we specialize in delivering top-notch drone solar surveying, mapping, and photography 
                services. 
                Trusted by leading solar companies, we take pride in serving not only the vibrant city of 
                Las Vegas but also the surrounding areas, extending our expertise to places like Pahrump, 
                Laughlin, Bullhead City, St. George, and Phoenix.
            </p>

            <h3>Our Commitment to Excellence</h3>
            <p>
                At the heart of our operations lies the 3 principles which guides everything we do. 
                Precision, reliability, and trust converge to form the cornerstone of our services, 
                ensuring that each and every project we undertake is executed with unparalleled attention 
                to detail and accuracy.
            </p>

            <h4>Precision</h4>
            <p>
                We understand the importance of precise data for your projects. 
                Our skilled drone pilots and with many hours of experience guarantee accuracy down to the finest detail. 
                You can rest assured that the results you receive are nothing short of excellence.
            </p>

            <h4>Reliability</h4>
            <p>
                When you choose Dronetri, you're choosing a partner you can rely on. 
                Our commitment to meeting deadlines, delivering consistent quality, and providing 
                exceptional customer service is unwavering. No matter the scale or complexity of your 
                project, we are here to get the job done efficiently and effectively.
            </p>

            <h4>Trusted</h4>
            <p>
                Building strong and lasting relationships with our clients is a fundamental 
                value we uphold. We understand the importance of trust when it comes to choosing a 
                drone service provider, and that's why we prioritize open communication, integrity, and 
                transparency throughout our collaboration.
            </p>
{/* 
            <h3>Join the DroneTri Family</h3>
            <p>
                We take great pride in being at the forefront of the drone services industry, 
                continuously pushing the boundaries of what's possible with this innovative technology. 
                With our expert team, state-of-the-art equipment, and a commitment to customer satisfaction, 
                we are dedicated to helping you achieve your goals with unparalleled efficiency and accuracy.
            </p> */}
            <p>
                Contact us today to discover how Dronetri can elevate your projects and unlock new 
                possibilities from above. Let's soar to new heights together!
            </p>
        </div>
    )
}

export default AboutUs;