import { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { NavLink, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './Home';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Services from './Services';
import Privacy from './legal/Privacy';
import Terms from './legal/Terms';

const initialForm = {
  name: '',
  email: '',
  project: 'solar survey',
  location: '',
  comments: ''
}

function App() {
  const [form, setForm] = useState(initialForm);
  const [formErrors, setFormErrors] = useState({});
  const [messages, setMessages] = useState([])
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const err = [];
    for(let errors in formErrors) {
      if(formErrors[errors] !== '')
        err.push(formErrors[errors]);
    }
    setMessages(err);
  }, [formErrors])

  const onChange = e => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const onSubmit = e => {
    e.preventDefault();

    const btn = document.querySelector('.submit-btn');
    console.log(btn)
    btn.value = 'sending...'

    const sendForm = {
      from_name: form.name,
      email: form.email,
      project: form.project,
      location: form.location,
      message: form.comments
    }

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_KEY,
        process.env.REACT_APP_TEMPLATE_ID,
        sendForm,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(res => {
        setMessages(['Message Sent Successfully']);
        console.log('sent');
        setForm(initialForm);
      })
      .catch(err => {
        btn.value = 'Submit'
        console.log('not sent');
      })
  }

  const dropdown = () => {
    const menu = document.querySelector('.dropdown-menu');
    menu.classList.contains('hide')
    ? menu.classList.remove('hide')
    : menu.classList.add('hide')
  }

  const navigate = useNavigate();

  return (
    <div className='App'>
      <HelmetProvider>
        {/* <h2>THIS SITE IS CURRENTLY UNDER CONSTRUCTION</h2> */}
        <header>
          <div className='logo' onClick={() => navigate('/')}>
            <h1>DroneTri</h1>
            <p>Precise | Reliable | Trusted</p>
          </div>
          <div className='dropdown' onClick={dropdown}>
            <div className='dropdown-btn'>
              <img className='dropdown-img' src='/menu-icon.png' alt='menu-icon' />
            </div>
          </div>
        </header>

        <nav className='dropdown-menu hide'>
          <NavLink onClick={dropdown} className='navlink' to='/' >Home</NavLink>
          <NavLink onClick={dropdown} className='navlink' to='services' >Services</NavLink>
          <NavLink onClick={dropdown} className='navlink' to='about' >About Us</NavLink>
          <NavLink onClick={dropdown} className='navlink' to='contact' >Contact Us</NavLink>
        </nav>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='services' element={<Services />} />
          <Route path='about' element={<AboutUs />} />
          <Route path='contact' element={
            <ContactUs
              form={form}
                onChange={onChange}
                onSubmit={onSubmit}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                messages={messages}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
              />}
          />
          <Route path='privacy' element={<Privacy />} />
          <Route path='terms' element={<Terms />} />
        </Routes>

        <footer>
          <nav id='footer-left'>
              <NavLink className='link' to='/' >Home</NavLink>
              <NavLink className='link' to='services' >Services</NavLink>
              <NavLink className='link' to='about' >About Us</NavLink>
              <NavLink className='link' to='contact' >Contact Us</NavLink>
              <NavLink className='link ftr-link' to='privacy' >Privacy Policy</NavLink>
              <NavLink className='link ftr-link' to='terms' >Terms of Service</NavLink>
          </nav>
          <div id='footer-right'>
            <div className='logo logo-footer'>
              <h1>DroneTri</h1>
            </div>
            <span>Email: contact@dronetri.com</span>
            <span id='copyright'>© 2023 Dronetri. All rights reserved.</span>
          </div>
        </footer>
      </HelmetProvider>
    </div>
  )
}

export default App;
