import '../Home/home.css';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return (
        <div>
            <Helmet>
                <title>Solar Surveying Drone Services</title>
                <meta name='description' content='Dronetri homepage' />
            </Helmet>
            <div className="home-collage">
                {/* <img alt='park-field' src='/park_1280x853.jpg' /> */}
                <video src='/collage_4mb.mp4' autoPlay={true} loop={true} muted={true} playsInline={true} />
            </div>
            <section>
            <p>
                Dronetri offers drone solar surveying, mapping, and photography.<br /><br />
                We serve Las Vegas and surrounding areas, including: <br/><br />
                Pahrump<br />Laughlin<br/> Bullhead City<br /> St. George<br /> Phoenix<br /><br />
                Our services are based on the principles, where precision, reliability, and trust converge to ensure our clients' complete satisfaction.
            </p>
            </section>
            <section>
                <div className='showcase-tri'>
                    <div className='showcase-itm'>
                        <img className='showcase-img' src='/accurate.png' alt='accurate-img' />
                        <h4>Precise</h4>
                        <p>We triple check our work to ensure our clients' needs are met with precision.</p>
                    </div>
                    <div className='showcase-itm'>
                        <img className='showcase-img' src='/thumbs-up.png' alt='thumbs up' />
                        <h4>Reliable</h4>
                        <p>When choosing Dronetri, you can count on us to consistently deliver quality results on time, every time.</p>
                    </div>
                    <div className='showcase-itm'>
                        <img className='showcase-img' src='/trusted.png' alt='handshake' />
                        <h4>Trusted</h4>
                        <div className='company-collage'>
                            <img className='company-collage-img' src='/palmetto-logo.svg' alt='palmetto' />
                            <img className='company-collage-img' src='/exactus-energy.png' alt='exactus energy' />
                            <img className='company-collage-img' src='/as.png' alt='action-solar' />
                            <img className='company-collage-img' src='/mona-lee.png' alt='mona lee' />
                            <img className='company-collage-img' src='/sunpower_logo.svg' alt='sunpower logo' />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div id='p107-container'>
                    <h2 id='p107-title'>Part 107 Certified</h2>
                    <div className='p107-section p107-txt'>
                        <p>
                            Pilots at Dronetri are <span className='strong'>Part 107 Certified</span> and are covered 
                            under a <span className='strong'>$1 Million</span> insurance policy.
                            Additonally, pilots actively stay up to date on rules and regulations.
                        </p>
                    </div>
                    <div className='p107-section p107-img'></div>
                </div>
            </section>
        </div>
    )
}

export default Home;